import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpService } from '../../services/http.service';
import { finalize, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import {
    CalculatePriceFullResponse,
    CalculatePriceLiteResponse,
    PriceParams,
    PriceSummary,
} from './price-summary.model';

@Injectable({
    providedIn: 'root',
})
export class PriceSummaryDataService {
    public isPriceSummaryLoading$: Subject<{ value: boolean }> = new Subject<{ value: boolean }>();

    public hasErrors$: Subject<{ value: boolean }> = new Subject<{ value: boolean }>();

    constructor(private httpService: HttpService) {}



    public fetchPriceSummary(params: PriceParams, full = true): Observable<PriceSummary> {
        let result: Observable<PriceSummary>;
        if (full) {
            result = this.httpService
                .post<CalculatePriceFullResponse>(`${environment.http.baseUrl}price/calculate`, params)
                .pipe(map((res) => ({ ...res, isFull: true })));
        } else {
            const url = `${environment.http.quotation}price/calculate/lite`;
            const { salesOrg, materials, discounts, conditions } = params;
            result = this.httpService
                .post<CalculatePriceLiteResponse>(url, {
                    salesOrg,
                    materials,
                    discounts,
                    conditions,
                    // Set value manually to false because it's required in backend
                    priceAdvantageEnabled: false
                })
                .pipe(map((res) => ({ ...res, isFull: false })));
        }

        return result.pipe(
            finalize(() => {
                this.isPriceSummaryLoading$.next({ value: false });
            })
        );
    }
}
